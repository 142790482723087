<template>
  <div :class="`amBox ${control === 'app' && 'amBoxTwo'}`">
    <div class="amOutside">
      <div class="amTitle">
        <p @click="changeModel('app')">
          <span :class="control === 'app' ? 'clickSpan' : '' ">APP下载</span>
          <img v-if="control === 'app'" src="../../../static/index/amRed.png" alt="">
        </p>
        <p @click="changeModel('mt4')">
          <span :class="control === 'mt4' ? 'clickSpan' : '' ">MT4下载</span>
          <img v-if="control === 'mt4'" src="../../../static/index/amRed.png" alt="">
        </p>
        <p @click="changeModel('mt5')">
          <span :class="control === 'mt5' ? 'clickSpan' : '' ">MT5下载</span>
          <img v-if="control === 'mt5'" src="../../../static/index/amRed.png" alt="">
        </p>
      </div>
      <div v-show="control === 'app'" class="aCenter">
        <div class="aBottom">
          <ul>
            <p>即刻下载 掌控您的财富先机</p>
            <li>
              <img src="../../../static/index/ad1.png" alt="">
              <span>顶尖技术团队倾力打造</span>
            </li>
            <li>
              <img src="../../../static/index/ad2.png" alt="">
              <span>2000+日夜精心打磨APP细节</span>
            </li>
            <li>
              <img src="../../../static/index/ad3.png" alt="">
              <span>200+万用户使用</span>
            </li>
            <li>
              <img src="../../../static/index/ad4.png" alt="">
              <span>权威平台认可</span>
            </li>
          </ul>
          <img class="acenterImg" src="../../../static/index/ad5.png" alt="">
          <div class="uldiv2">
            <div class="uldiv2-title">下载MT4账户专用版本</div>
            <div>
                <a :href="iosMt4DownloadUrl">
                <img src="../../../static/index/btn_ios.png" alt="">
                </a>
                
            </div>
            <div>
              <a @click="handleDownload(androidMt4DownloadUrl)">
                <img src="../../../static/index/btn_android.png" alt="">
                </a>
            </div>
          </div>
          <div class="uldiv2">
            <div class="uldiv2-title">下载MT5账户专用版本1</div>
            <div>
                <a :href="iosMt5DownloadUrl">
                <img src="../../../static/index/btn_ios.png" alt="">
                </a>
                
            </div>
            <div>
              <a @click="handleDownload(androidDownloadUrl)">
                <img src="../../../static/index/btn_android.png" alt="">
                </a>
            </div>
          </div>
        </div>
        <div class="warm-uldiv">
          <div class="uldiv">
            <div class="uldiv-box">
              <div class="uldiv-box-title">
                下载MT4账户专用版本
              </div>
              <div class="uldiv-box-code">
                <p v-if="showIos">
                  <span>
                    <Qrcode :link="iosMt4DownloadUrl" :size="140" :iconSize="30"/>
                  </span>
                  <span>IOS</span>
                </p>
                <p>
                  <span>
                    <Qrcode :link="androidMt4DownloadUrl" />
                  </span>
                  <span>安卓下载</span>
                </p>
              </div>
            </div>
            <div class="uldiv-box">
              <div class="uldiv-box-title uldiv-box-title-two">
                下载MT5账户专用版本
              </div>
              <div class="uldiv-box-code">
                <p v-if="showIos">
                  <span>
                    <Qrcode :link="iosMt5DownloadUrl" :size="140" :iconSize="30"/>
                  </span>
                  <span>IOS</span>
                </p>
                <p>
                  <span>
                    <Qrcode :link="androidDownloadUrl" />
                  </span>
                  <span>安卓下载</span>
                </p>
              </div>
            </div>
          </div>
          <div class="warm-tips">
            <div>
              <div class="warm-tips-title">温馨提示：</div>
              <div>
                1.MT4版本，仅MT4账户可用；MT5版本，仅MT5账户可用，两者不互通。 <br>
                2.本平台2024年8月31日10：00之前开立的账户，均为MT4账户。之后开立的账户均为MT5账户。如果不确定，请联系在线客服查询。
              </div>
            </div>
            <div class="warm-tips-service" @click="handleService">
              <img src="../../../static/common/service.png" alt="">
              <div>联系客服</div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="control === 'mt4'" class="mCenter">
        <img class="mt4Img" src="../../../static/index/mt4Img.png" alt="">
        <ul>
          <li>MT4下载</li>
          <li>
            MT4是一款综合了行情图表、技术分析、交易操作三大功能于一身，可进行下单、平仓限价单、止损、止盈、查看报表及分析数据等不同操作的强大功能软件，是目前非常广泛使用的行情软件之一。
            <br>
            目前在全球有超过300家经纪商及银行正在使用MT4平台，是全球最受欢迎的在线交易平台。
          </li>
          <li class="mtPcDownload">
            <p>
              <span class="span1 banner">
                <img class="sbImg" src="../../../static/common/ios_code.png" alt="">
              </span>
              <span>IOS</span>
            </p>
            <p>
              <span class="span2 banner">
                <img class="sbImg" src="../../../static/common/android_code.png" alt="">
              </span>
              <span>Android</span>
            </p>
            <p @click="windowsDownload('MT4')">
              <span class="span3 banner2">
                <!-- <img src="../../../static/common/windows_code.png" alt=""> -->
              </span>
              <span>Windows</span>
            </p>
          </li>
          <li class="mtWapDownload">
            <img class="mt4Img" src="../../../static/index/mt4Img.png" alt="">
            <div @click="Appstoreload('MT4')">
              <img src="../../../static/index/btn_ios.png" width="230" height="53" alt="">
            </div>
            <div @click="Androidload('MT4')">
              <img src="../../../static/index/btn_android.png" width="230" height="53" alt="">
            </div>
          </li>
        </ul>
      </div>
      <div v-show="control === 'mt5'" class="mCenter">
        <img class="mt5Img" src="../../../static/index/mt5Img.png" alt="">
        <ul>
          <li>MT5下载</li>
          <li>
            MT5是一个功能强大且专业的金融交易软件，支持外汇、股票、期货等多种金融产品交易。提供了丰富的图表分析工具、技术指标和自动化交易功能，帮助交易者更有效地分析市场趋势和实现交易自动化。MT5更适合专业交易者和有经验的投资者。
          </li>
          <li class="mtPcDownload">
            <p>
              <span class="span1 banner">
                <img class="sbImg" src="../../../static/common/ios_code_mt5.png" alt="">
              </span>
              <span>IOS</span>
            </p>
            <p>
              <span class="span2 banner">
                <img class="sbImg" src="../../../static/common/android_code_mt5.png" alt="">
              </span>
              <span>Android</span>
            </p>
            <p @click="windowsDownload('MT5')">
              <span class="span3 banner2">
                <!-- <img src="../../../static/common/windows_code.png" alt=""> -->
              </span>
              <span>Windows</span>
            </p>
          </li>
          <li class="mtWapDownload">
            <img class="mt5Img" src="../../../static/index/mt5Img.png" alt="">
            <div @click="Appstoreload('MT5')">
              <img src="../../../static/index/btn_ios.png" width="230" height="53" alt="">
            </div>
            <div @click="Androidload('MT5')">
              <img src="../../../static/index/btn_android.png" width="230" height="53" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState  } from "vuex";
  import Qrcode from '../QrCode/index.vue'
export default {
  name:'amDownload',
  components: {
    Qrcode,
  },
  data() {
    return {
      SPuRl:require('../../../static/index/androidcode.png'),
      APKurl:'',
      control: 'app',
      showIos:true,
      // mt4ImgIos:{
      //   mouseover:require('../../../static/common/ios_code.png'),
      //   mouseleave:require('../../../static/index/mt4ios.png')
      // }
    }
  },
  computed: {
    ...mapState('common', {
      androidMt4DownloadUrl: 'androidMt4DownloadUrl',
      androidDownloadUrl: 'androidDownloadUrl',
      iosMt4DownloadUrl: 'iosMt4DownloadUrl',
      iosMt5DownloadUrl: 'iosMt5DownloadUrl',
    })
  },
  created(){
    const Rhref = window.location.host;
    if(Rhref.indexOf('rlchltd')>-1){
      //this.showIos = false
    }else{
      this.showIos = true
    }
  },
  methods:{
    // 控制两个模块相互切换
    changeModel(val){
      this.control = val;
    },
    // 
    windowsDownload(type){
      if(type === 'MT5') {
        window.open('https://download.mql5.com/cdn/web/hong.kong.red/mt5/hkredlioncapital5setup.exe');
      } else {
        window.open('https://download.mql5.com/cdn/web/hong.kong.red/mt4/hkredlioncapital4setup.exe');
      }
    },
    // 联系客服
    handleService() {
      const url = sessionStorage.getItem('liveUC')
      window.open(url)
    },
    //ios设备下载
    Appstoreload(type) {
      const userAgent = navigator.userAgent;
      console.log(userAgent);
      let deviceType = '';
      const downloadUrl = type === 'MT4' ? 'https://apps.apple.com/cn/app/metatrader-4/id496212596' : 'https://download.mql5.com/cdn/mobile/mt5/ios?server=HKRedLionCapital-Server';
      if (/android/i.test(userAgent)) {
        // 判断为安卓设备
        deviceType = 'Android';
        this.$message(`当前设备为: ${deviceType},请下载对应App`);
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // 判断为苹果iOS设备，同时排除Windows Phone等可能包含'iPad'、'iPhone'、'iPod'字样的设备
        deviceType = 'iOS';
        window.open(downloadUrl);
      } else {
        // 其他设备，如桌面设备等
        deviceType = 'Other';
        window.open(downloadUrl);
      }
    },
    //安卓设备下载
    Androidload(type) {
      const userAgent = navigator.userAgent;
      console.log(userAgent);
      let deviceType = '';
      const downloadUrl = type === 'MT4' ? 'https://files.metaquotes.net/metaquotes.software.corp/mt4/metatrader4.apk' : 'https://download.mql5.com/cdn/mobile/mt5/android?server=HKRedLionCapital-Server';
      const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1; //android终端
      if(isAndroid && window.android) {
        window.android.onOpenUrl(downloadUrl);
      } else if (/android/i.test(userAgent)) {
        // 判断为安卓设备
        deviceType = 'Android';
        window.open(downloadUrl);
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // 判断为苹果iOS设备，同时排除Windows Phone等可能包含'iPad'、'iPhone'、'iPod'字样的设备
        deviceType = 'iOS';
        this.$message(`当前设备为: ${deviceType},请下载对应App`);
      } else {
        // 其他设备，如桌面设备等
        deviceType = 'Other';
        window.open(downloadUrl);
      }
    },
    handleDownload(link) {
      const userAgent = navigator.userAgent;
      const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1; //android终端
      if(isAndroid && window.android) {
        window.android.onOpenUrl(link);
      } else {
        window.open(link);
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .amBox{
    width: 100%;
    height: 1030px;
    background: url('../../../static/index/amBanner.png') no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding: 120px 0;
    margin: 120px 0;
    .amOutside{
      width: 1216px;
      height: 100%;
      margin: 0 auto;
      .amTitle{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span{
            color: #666666;
            font-size: 36px;
            letter-spacing: 2px;
            margin-bottom: 30px;
          }
          .clickSpan{
            color: #000000;
          }
        }
        p:nth-child(2){
          margin: 0 90px;
        }
      }
      .aCenter{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        padding-top: 70px;
        .aBottom {
          width: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          box-sizing: border-box;
          ul{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            p{
              font-size: 36px;
              color: #333333;
              margin-bottom: 55px;
            }
            li{
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 30px;
              span{
                color: #333134;
                margin-left: 20px;
                font-size: 18px;
              }
            }
          }
          .acenterImg {
            width: 48%;
            position: relative;
            top: -20px;
            // left: -50px;
          }
          .uldiv2{
            display: none;
          }
        }
        .warm-uldiv {
          display: flex;
          align-items: center;
          width: 1416px;
          position: relative;
          left: -100px;
          .warm-tips {
            text-align: left;
            display: flex;
            width: 40%;
            align-items: center;
            background: #fcfdfe;
            padding: 56px 30px;
            box-sizing: border-box;
            line-height: 24px;
            justify-content: space-between;
            cursor: pointer;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            margin-left: 4px;
            &-title {
              font-weight: 600;
              font-size: 16px;
              margin-bottom: 10px;
            }
            &-service {
              flex-shrink: 0;
              text-align: center;
              background: #fff;
              font-size: 16px;
              padding: 20px;
              border-radius: 8px;
              margin-left: 20px;
              img {
                margin-bottom: 8px;
                width: 30px;
              }
            }
          }
        }
        .uldiv{
          width: 70%;
          margin: 0 auto;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          overflow: hidden;
          box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
          &-box {
            width: 50%;
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
            &-title {
              padding: 16px 0;
              font-size: 20px;
              text-align: center;
              background: linear-gradient(to right, #eaf1ff, #f5f4fb);
            }
            &-title-two {
              background: linear-gradient(to right, #f5f4fb, #fef6f7);
            }
            &-code {
              background: #fcfdfe;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 25px 0;
            }
            p{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              span:nth-child(1){
                width: 180px;
                height: 180px;
                background: url('../../../static/index/mt4ios.png') no-repeat;
                background-size: cover;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .canvasCode{
                display: none;
              }
              span{
                color: #333333;
                font-size: 18px;
                margin-top: -10px;
              }
              &:hover {
                span:nth-child(1){
                  background: url('../../../static/index/juxingBanner.png') no-repeat;
                  background-size: cover;
                }
                .canvasCode {
                  display: block;
                }
              }
            }
            p:nth-child(2){
              span:nth-child(1){
                width: 180px;
                height: 180px;
                background: url('../../../static/index/mt4android.png') no-repeat;
                background-size: cover;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .canvasCode {
                display: none;
              }
              &:hover {
                span:nth-child(1){
                  background: url('../../../static/index/juxingBanner.png') no-repeat;
                  background-size: cover;
                }
                .canvasCode {
                  display: block;
                }
              }
            }
          }
        }
      }
      .mCenter{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mt4Img{
          width: 726px;
          height: auto;
        }
        .mt5Img{
          width: auto;
          height: 510px;
          margin-left: 50px;
        }
        ul{
          width: 38%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-left: 25px;
          li{
            width: 100%;
          }
          li:nth-child(1){
            font-size: 36px;
            color: #343434;
            margin-bottom: 20px;
          }
          li:nth-child(2){
            font-size: 18px;
            color: #333230;
            line-height: 30px;
            margin: 40px 0;
          }
          .mtPcDownload{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: -20px;
            p{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              img{
                width: 120px;
                height: auto;
                display: none;
              }
              span{
                font-size: 14px;
                color: #989a99;
                margin-top: 15px;
              }
              .banner{
                width: 170px;
                height: 170px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .banner2{
                width: 170px;
                height: 170px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .span1{
                background: url('../../../static/index/mt4ios.png') no-repeat;
                background-size: cover;
              }
              .span2{
                background: url('../../../static/index/mt4android.png') no-repeat;
                background-size: cover;
              }
              .span3{
                background: url('../../../static/index/mt4win.png') no-repeat;
                background-size: cover;
              }
            }
            p:hover .banner{
              background: url('../../../static/index/juxingBanner.png') no-repeat;
              background-size: 170px 170px;
            }
            p:hover img{
              display: block;
            }
          }
          .mtWapDownload {
            display: none;
          }
        }
      }
    }
  }
  .amBoxTwo {
    height: 1330px;
  }
  @media screen and (max-width: 500px) {
    .amBox{
      height: auto;
      padding: 50px 0 0;
      margin: 50px 0;
      box-sizing: border-box;
      .amOutside{
        width: 100%;
        box-sizing: border-box;
        .amTitle{
          width: 80%;
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          p{
            .clickSpan{
              font-size: 20px;
              letter-spacing: 0;
            }
            span{
              color: #666666;
              font-size: 18px;
              letter-spacing: 0px;
              margin-bottom: 10px;
            }
            img{
              height: 2px;
            }
          }
          p:nth-child(2){
            margin: 0 30px;
          }
        }
        .aCenter{
          flex-direction: column;
          padding-top: 20px;
          .aBottom {
            display: block;
            width: 100%;
            ul{
              width: 80%;
              margin: 0 auto;
              justify-content: flex-start;
              align-items: flex-start;
              p{
                font-size: 24px;
                margin: 0px auto 20px;
              }
              li{
                box-sizing: border-box;
                margin-bottom: 15px;
                img{
                  width: 45px;
                }
                span{
                  font-size: 16px;
                }
              }
            }
            .acenterImg{
              width: 80%;
              height: auto;
              margin: 30px auto 0;
              top: 0;
              left: 10%;
            }
            .uldiv2{
              text-align: center;
              display: block;
              width: 90%;
              margin: 25px auto 0;
              border-radius: 8px;
              overflow: hidden;
              box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
              background: #ffffff;
              padding-bottom: 10px;
              &-title {
                background: linear-gradient(to right, #eaf1ff, #fef5f7);
                padding: 11px 0;
                margin-bottom: 25px;
              }
              a{
                display: block;
                margin: 0 auto 10px;
                img{
                  width: 200px;
                }
              }
            }
            .warm-tips-h5 {
              width: 90%;
              padding: 20px 16px;
              margin: 30px auto 30px;
              font-size: 13px;
              line-height: 18px;
              &-title {
                font-size: 14px;
                margin-bottom: 6px;
              }
              &-service {
                margin-left: 16px;
                font-size: 13px;
                padding: 8px;
                img {
                  width: 24px;
                  margin-bottom: 4px;
                }
              }
            }
          }
          .warm-uldiv {
            width: 100%;
            left: 0;
            .warm-tips {
              width: 90%;
              padding: 20px 16px;
              margin: 30px auto 30px;
              font-size: 13px;
              line-height: 18px;
              border-radius: 8px;
              &-title {
                font-size: 14px;
                margin-bottom: 6px;
              }
              &-service {
                margin-left: 16px;
                font-size: 13px;
                padding: 8px;
                img {
                  width: 24px;
                  margin-bottom: 4px;
                }
              }
            }
          }
          .uldiv{
            display: none;
          }
        }
        .mCenter{
          flex-direction: column;
          padding-bottom: 20px;
          .mt4Img, .mt5Img{
            display: none;
          }
          ul{
            width: 80%;
            margin: 30px auto 0;
            li:nth-child(1){
              font-size: 24px;
            }
            li:nth-child(2){
              font-size: 14px;
              margin: 0 auto;
            }
            .mtPcDownload{
              display: none;
            }
            .mtWapDownload {
              display: block;
              margin-bottom: 20px;
              text-align: center;
              .mt4Img, .mt5Img{
                display: block;
                width: 100% !important;
                height: auto;
                margin: 40px auto;
              }
              div {
                display: block;
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
</style>